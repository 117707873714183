<template>
  <div>
    <!-- 搜索 -->
    <div class="operation_wrap">
      <div style="width: 580px">
        <el-input
          size="small"
          v-model="listQuery.keyword"
          clearable
          placeholder="请输入名字\邮箱\用户名搜索"
        >
          <el-button
            size="small"
            slot="append"
            icon="el-icon-search"
            @click="initGetUsers"
          ></el-button>
        </el-input>
      </div>
      <el-select size="small" v-model="job" placeholder="请选择职业">
        <el-option
          v-for="item in jobs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 表格开始 -->
    <el-scrollbar style="height: calc(70vh - 40px)">
      <el-table
        v-loading="loading"
        :data="personList"
        border
        style="width: 100%"
      >
        <el-table-column prop="id" label="序列号" width="70" align="center">
        </el-table-column>
        <el-table-column
          prop="roles"
          label="身份"
          width="150"
          :formatter="dealRoles"
        >
        </el-table-column>
        <el-table-column prop="chinaname" label="中文名" width="200">
        </el-table-column>
        <el-table-column prop="name" label="用户名" width="150">
        </el-table-column>
        <el-table-column
          label="职位"
          width="150"
        >
        <template #default="{row}">
          {{ row.officium | filterRole }}
        </template>
        </el-table-column>
        <el-table-column
          prop="roles"
          label="查看权限"
          width="160"
          :formatter="formatBoolean"
        >
        </el-table-column>
        <el-table-column prop="email" label="邮箱" width="auto">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="setRoles(scope.row.id)">
              设职
            </el-button>
            <el-button type="text" @click="handleSetAdmin(scope.row.id)">
              设权
            </el-button>
            <el-button type="text" @click="handleDelUser(scope.row.id)">
              <span style="color:red">删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <el-pagination
      style="float: right;margin-top: 12px;"
      background
      @current-change="handleChange('page', $event)"
      @size-change="handleChange('page_long', $event)"
      :current-page.sync="listQuery.page"
      :page-size="listQuery.page_long"
      layout="total,prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getUsers, setAdmin, delUser, setRole } from "@/api/home";
import { getByTitle } from "@/api/config";
export default {
  name: "survivor",
  data() {
    return {
      personList: [],
      //加载
      loading: false,
      //分页
      listQuery: {
        page: 1,
        page_long: 10,
        keyword: "",
      },
      total: 1000,
      job: "",
      jobs:[],
    };
  },
  created() {
    this.initGetUsers();
    this.initRoles();
  },
  methods: {
    initGetUsers() {
      this.loading = true;
      getUsers(this.listQuery)
        .then((res) => {
          this.total = res.data.count;
          this.personList = res.data.user;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 设置管理员
    handleSetAdmin(id) {
      setAdmin(id)
        .then(() => {
          this.$message.success("设置成功！");
          this.initGetUsers();
        })
        .catch(() => {
          this.$message.error("设置失败，请联系超级管理员！");
        });
    },
    // 删除用户
    handleDelUser(id) {
      this.$confirm("此操作将永久删除该用户所有信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delUser(id)
            .then(() => {
              this.$message.success("删除成功！");
              this.initGetUsers();
            })
            .catch(() => {
              this.$message.error("删除失败，请联系超级管理员！");
            });
        })
        .catch(() => {});
    },
    //分页改变
    handleChange(prop, value) {
      this.listQuery = {
        ...this.listQuery,
        [prop]: value,
      };
      console.log("this.listQuery", this.listQuery);
      this.initGetUsers();
    },
    //设置职位
    setRoles(id) {
      setRole(id, this.job)
        .then(() => {
          this.$message.success("设置成功！");
          this.initGetUsers();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    dealRoles(row, column, cellValue) {
      let rec = "";
      if (cellValue.includes("nbadmin")) {
        rec = "超级管理员";
      } else if (cellValue.includes("admin")) {
        rec = "管理员";
      } else {
        rec = "普通用户";
      }
      return rec;
    },

    //formatter
    formatBoolean(row, column, cellValue) {
      var ret = ""; //你想在页面展示的值
      if (cellValue.includes("nbadmin")) {
        ret = "管理所有成员"; //根据自己的需求设定
      } else if (cellValue.includes("admin")) {
        ret = "管理官网用户成员";
      } else {
        ret = "无权限";
      }
      return ret;
    },
    initRoles(){
      getByTitle("roleList").then(res=>{
        this.jobs = res.data;
      })
      .catch((err)=>{
        this.$message.error("获取云端配置失败！")
      })
    }
  },
};
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 20px;
}
.operation_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.el-input {
  margin: 10px;
  width: 70%;
}
</style>
